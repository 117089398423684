import { OutputAuthorType } from "./User"
import { FileType, FileTypeType } from "./Post"
import { PickedVideoType } from "./Media/Video"
import { Overwrite } from "../utils"
import { ReactionGetType, ReactionType } from "./Reaction"
// import { ImageType } from './Image';
type ImageInfo = any

export type CommentType = {
    pk: string;
    text: string;
    file?: string;
    thumbnail?: string;
    video_duration: number
    file_type: FileTypeType;
    // author: OutputAuthorType;
    output_author: OutputAuthorType;
    to_comment_object: ToCommentObjectType | null;
    post_pk: string;
    community: number;
    deleted: boolean;
    is_mine: boolean;
    is_anonymous: boolean;
    author_is_blocked_by_request_user: boolean;
    created_at: string;
    author_pen_name?: string;
    reactions: ReactionGetType[]
}

export type ArrangedCommentType = CommentType & {
    sameAuthorAsPreviousComment: boolean
}

export type ImageCommentType = Overwrite<CommentType, {
    file: string
    thumbnail: undefined
}>

export const isVideoComment = ( comment: any ): comment is VideoCommentType => comment.file_type === FileType.VIDEO
export const isImageComment = ( comment: any ): comment is ImageCommentType => comment.file_type === FileType.IMAGE

export type ToCommentObjectType = {
    author: string;
    deleted: boolean;
    output_author: {
        name: string
    };
    text: string;
    pk: string;
}

export type VideoCommentType = CommentType & {
    file: string
    thumbnail: string
}

export type MediaType = ImageInfo | PickedVideoType | ( ImageInfo & {
    data: any
    name: string
} )
// export type ReplyTargetType = {
//     name: string
//     pk: string
//     author: string
//     text: string
// }

export type ReplyTargetType = CommentType

export type SavedCommentType = {
    postID: string
    text: string
    timestamp: number
}
