import React, { useState, useRef, useEffect, useContext } from "react"
import Styles from "../styles/PostForm.module.scss";
import Layout from "../components/Layout"
import SEO from "../components/seo"
import TextareaAutosize from 'react-textarea-autosize';
import clsx from "clsx"
import GeneralButton from "../components/atoms/GeneralButton";
import LocalFile from "../components/LocalFile"
import amplitude, { logErrorEvent, logEvent } from "../Analytics";
import { useMediaQuery } from 'react-responsive'
import { MentionModal } from "../components/MentionModal"
import { ToastContext } from '../context/ToastContext';
import { sleep } from "../functions/sleep"
import RemoteFile from "../components/RemoteFile";
import { isVideoComment } from "../types/models/Comment";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import client from "../apis/client";
import { FileIcon } from "../icons/FileIcon";

const CommentUpdatePage = ( {
    location
}: any ) => {

    const defaultComment = location.state ?
        location.state.comment :
        {}


    const isBigScreen = useMediaQuery( { query: '(min-width: 900px)' } )

    const [ comment, setComment ] = useState( defaultComment );
    const [ text, setText ] = useState( comment ? comment.text : "" )
    const [ file, setFile ] = useState<File | null>( null )
    const [ trimmedVideo, setTrimmedVideo ] = useState<Blob | null>( null );
    const [ videoDuration, setVideoDuration ] = useState( 0 )
    const [ uploadRatio, setUploadRatio ] = useState( 0 )
    const [ uploading, setUploading ] = useState( false )
    const [ canUploadVideo, setCanUploadVideo ] = useState( true );
    const [ isFileDialogOpen, setIsFileDialogOpen ] = useState( false );
    const isFileDialogOpenRef = useRef( isFileDialogOpen )
    const { addToast } = useContext( ToastContext );


    const textarea = useRef<HTMLTextAreaElement>( null );
    const fileInputRef = useRef<HTMLInputElement>( null )

    useEffect( () => {
        isFileDialogOpenRef.current = isFileDialogOpen
    }, [ isFileDialogOpen ] )

    useEffect( () => {
        setTrimmedVideo( null )
    }, [ file ] )

    useEffect( () => {
        textarea.current && textarea.current.focus()
        document.addEventListener( "drop", dropFile, false )
        document.addEventListener( "dragover", handleDragOver, false )
        componentDidMount()
        return () => {
            document.removeEventListener( "drop", dropFile, false )
            document.removeEventListener( "dragover", handleDragOver, false )
        }
    }, [] )

    const dropFile = ( e: DragEvent ) => {
        e.preventDefault()
        e.stopPropagation()
        const dataTransfer = e.dataTransfer
        if ( !dataTransfer ) return
        const files = dataTransfer.files
        setFile( files[ 0 ] )
    }

    const handleDragOver = ( e: DragEvent ) => {
        e.preventDefault()
        e.stopPropagation()
    }


    const componentDidMount = async () => {
        await client.get( `/api/video_upload_permission/`)
            .then( response => setCanUploadVideo( response.data.canUploadVideo ) )
            .catch( error => console.log( error.response ) )
    }

    const generateFileName = ( file: File ) => {
        let fileName = 90 < file.name.length ?
            file.name.slice( file.name.length - 90 ) :
            file.name
        fileName = fileName.includes( "." ) ?
            fileName :
            fileName + ".mp4"
        return fileName
    }

    const attatchFile = ( data: FormData ) => {
        if ( !file ) return
        const fileName = generateFileName( file )
        trimmedVideo ?
            data.append( "file", trimmedVideo, fileName ) :
            data.append( "file", file, fileName )
    }

    const attatchNullToFile = ( data: FormData ) => data.append( "file", "null" )

    const attatchText = ( data: FormData ) => data.append( "text", text )


    const validateUploadData = async () => {
        if ( uploading ) return false
        if ( !text && comment.file_type === 0 && !file ) {
            // alert( 'テキストを入力してください' );
            addToast( { text: "テキストを入力するか、\nファイルを添付してください", type: "warn" } )
            return false
        }
        if ( !text && !comment.file && !file ) {
            // alert( 'テキストを入力してください' );
            addToast( { text: "テキストを入力するか、\nファイルを添付してください", type: "warn" } )
            return false
        }
        if ( text.length > 2000 ) {
            addToast( { text: "テキストは2000字までです\nコメントに文章を分けるなどしてください", type: "warn" } )
            return false
        }

        return true
    }

    const update = async () => {
        if ( ! await validateUploadData() ) return
        const data = new FormData()
        attatchText( data )
        if ( file ) attatchFile( data )
        else if ( !comment.file ) attatchNullToFile( data ) // 削除
        setUploading( true )
        await client.patch( `/api/comment/${ comment.pk }/`, data, {
            onUploadProgress: onUpload,
            // リクエストのキャンセルする時に使うキャンセルトークンの設定
            // cancelToken: source.token
        } ).
            then( ( response ) => {
                setText( "" )
                setFile( null )
                setUploading( false )
                addToast( { text: "変更しました", type: "success" } )
                // alert( "変更しました" )
                logEvent( amplitude, "Action-CommentUpdate", { web: true } )
                window.history.back()
            } )
            .catch( error => {
                setUploading( false )
                if ( error.response ) {
                    if ( error.response.status == 402 ) {
                        addToast( {
                            text: error.response.data.message,
                            type: "error",
                            duration: 6000
                        } )
                    }
                    else {
                        addToast( {
                            text: "変更に失敗しました",
                            type: "error"
                        } )
                    }
                }
                console.log( error.response )
                logErrorEvent( amplitude, "Error-Comment", error, { web: true } )
                addToast( { text: "変更に失敗しました", type: "error" } )
            } )
    }

    const getVideoDuration = ( duration: number ) => setVideoDuration( duration )

    const onUpload = ( e: ProgressEvent ) => {
        const ratio = ( e.loaded / e.total )
        setUploadRatio( ratio )
    }

    const onChangeText = ( name: string, value: string ) => setText( value )

    const onChangeFile = () => {
        if ( !fileInputRef.current ) return
        if ( !fileInputRef.current.files ) return
        const file = fileInputRef.current.files[ 0 ]
        if ( !file ) return

        if ( file.size > ( 200 * 1000 * 1000 ) ) {
            addToast( { text: "動画は200メガバイトまでです\nトリミングをするか、画質を落としてください", type: "warn" } )
            // alert( '動画は200メガバイトまでです\nトリミングをするか、画質を落としてください' )
            return
        }

        if ( file.size > ( 100 * 1000 * 1000 ) ) {
            addToast( { text: "サイズの大きなファイルです\n投稿ページが重くなる場合があります", type: "warn" } )
            // alert( 'サイズの大きなファイルです\n投稿ページが重くなる場合があります' )
            if ( !window.confirm( "このまま添付しますか？" ) ) {
                return
            }
        }

        if ( file.name.includes( "." ) ) {
            setFile( file )
            return
        }

        if ( window.confirm( "拡張子なしのこのファイルは、動画として扱われます。\nよろしいですか？" ) ) {
            setFile( file )
            return
        }
        addToast( { text: "適切な拡張子に変換してからアップロードしてください", type: "warn" } )
        // alert( "適切な拡張子に変換してからアップロードしてください" )
        fileInputRef.current.value = ""
    }

    const cancelEdit = () => {
        const originalText = comment.text
        if ( text === originalText ) {
            window.history.back()
            return
        }
        if ( text ) {
            return
        }
        window.history.back()
    }

    const focusInputAndMoveCursor = ( cursorPosition: number ) => {
        if ( !textarea.current ) return
        textarea.current.focus()
        textarea.current.setSelectionRange( cursorPosition, cursorPosition );
    }

    const pickMedia = async () => {
        if ( canUploadVideo ) {
            fileInputRef.current && fileInputRef.current.click()
        }
        else {
            setIsFileDialogOpen( false )
            !canUploadVideo && addToast( {
                text: "動画のアップロード上限に達しているため、動画は送信できません。\nご注意ください",
                type: "warn",
                position: "center",
                onDismiss: () => {
                    fileInputRef.current && fileInputRef.current.click()
                    setIsFileDialogOpen( true )
                }
            } )
            await sleep( 4000 )
            !isFileDialogOpenRef.current && fileInputRef.current && fileInputRef.current.click()
            setIsFileDialogOpen( true )
        }
    }

    return (
        <Layout>
            <>
                <SEO title="CommentForm" />
                <div
                    className={ Styles.container }>
                    <div className={ Styles.button_container }>
                        <b><span style={ { color: Styles.main_color, marginLeft: 8 } } onClick={ () => cancelEdit() }>キャンセル</span></b>
                        { uploading &&
                            <div className={ Styles.progress_bar_wrapper }>
                                <progress value={ uploadRatio * 100 } max={ 100 } />
                                <span>{ Math.floor( uploadRatio * 100 ) }%</span>
                            </div>
                        }
                        <div style={ { display: "inline-flex", alignItems: "center" } }>
                            <GeneralButton
                                onClick={ () => update() }
                                title="変更"
                                loading={ uploading }
                                style={ { padding: "8px 20px", borderRadius: 20 } } />
                        </div>
                    </div>
                    <TextareaAutosize
                        onChange={ e => onChangeText( "text", e.target.value ) }
                        // inputRef={ tag => { if ( textarea.current ) textarea.current = tag } }
                        inputRef={ textarea }
                        name="text"
                        placeholder={ "コメントを変更" }
                        autoFocus
                        value={ text }
                        maxRows={ 10 }
                        minRows={ isBigScreen ? 6 : 4 }
                        className={ `${ Styles.textarea } ${ Styles.text_contained_textarea }` }>
                    </TextareaAutosize>
                    <MentionModal
                        text={ text }
                        setText={ setText }
                        focusInput={ focusInputAndMoveCursor }
                        changeLayout={ () => { } } />
                    <div className={ Styles.option_container_wrapper }>
                        <div
                            className={ Styles.option_container }
                            style={ { borderTop: "none", paddingTop: 0 } }>
                            <FileIcon
                                className={ clsx( Styles.icon, Styles.file_icon ) }
                                onClick={ () => pickMedia() } />
                            <input
                                ref={ fileInputRef }
                                type="file"
                                name=""
                                id=""
                                onChange={ ( e ) => onChangeFile() } />
                        </div>
                    </div>
                    <div style={ { width: "100%", textAlign: "center" } }>
                        { file &&
                            <LocalFile
                                file={ file }
                                getVideoDuration={ getVideoDuration }
                                trimmedVideo={ trimmedVideo }
                                setTrimmedVideo={ setTrimmedVideo }
                                removeFile={ () => {
                                    if ( fileInputRef.current ) fileInputRef.current.value = "";
                                    setFile( null )
                                    setTrimmedVideo( null )
                                } } /> }
                        <div>
                            { comment.file && !file &&
                                <RemoteFile
                                    fileURL={ comment.file }
                                    fileType={ isVideoComment( comment ) ? "video" :
                                        isVideoComment( comment ) ?
                                            "image" :
                                            "" }
                                    getVideoDuration={ getVideoDuration }
                                    // trimmedVideo={ () => { } }
                                    // setTrimmedVideo={ setTrimmedVideo }
                                    removeFile={ () => {
                                        // comment.file = null
                                        setComment( { ...comment, file: null } )
                                        // fileInputRef.current.value = "";
                                        // setFile( null )
                                        // setTrimmedVideo( null )
                                    } }
                                /> }
                        </div>
                    </div>
                </div>
            </>
        </Layout>
    );
}


export default CommentUpdatePage
